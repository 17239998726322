package tech.gensert.portal.web.pages

import kotlinx.browser.window
import react.FC
import react.Props
import react.router.useNavigate
import react.useEffect
import react.useState
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.web.wrappers.useMsal

val LogoutPage = FC<Props> {
//    console.log("LogoutPage is rendering")
    val context = useMsal()
    val (logoutComplete, setLogoutComplete) = useState(false)

    val instance = context.instance
    val navigate = useNavigate()
    useEffect(dependencies = emptyArray()) {

        instance.logoutRedirect()
            .then {
//                console.log("Logout completed.")
                setLogoutComplete(true)
                navigate("/")

            }
            .catch { e ->
                console.error("Error during logout: ${e.message}")
                setLogoutComplete(true)
                navigate("/")
            }
    }
}

fun clearCookies() {
    val cookiesToClear = arrayOf(
        CookieNames.UserAccountId,
        CookieNames.gt_login_hint,
        CookieNames.UserName,
        CookieNames.Tokens
    )
    cookiesToClear.forEach { cookieName ->
        window.document.asDynamic().cookie = "$cookieName=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
    }
}
