import js.errors.JsError
import kotlinext.js.js
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import mui.icons.material.AttachMoney
import mui.icons.material.Done
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.*
import tech.gensert.portal.common.model.CheckoutSession
import tech.gensert.portal.common.model.PlanService
import tech.gensert.portal.common.model.SessionService
import tech.gensert.portal.common.model.User
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.common.model.config.getCookie
import tech.gensert.portal.web.showcase.Reusables.WebReusables.MODE
import tech.gensert.portal.web.showcase.Reusables.WebReusables.SUCCESS_URL
import tech.gensert.portal.web.wrappers.useMsal
import web.cssom.*


@Serializable
data class CardData(
    val title: String?,
    val description: String?,
    val price: String?,
    val productId: String,
)


@OptIn(DelicateCoroutinesApi::class)
val CardShowcase = FC<Props> {
    val context = useMsal()
    val account = context.accounts[0]
    val theme = useTheme()
    var (dataLoaded, setDataLoaded) = useState(false)
    val initialCardDataList = useRef(emptyList<CardData>())
    var (cardDataList, setCardDataList) = useState(initialCardDataList.current)
    var (selectedPlan, setSelectedPlan) = useState(null)
    var (selectedInterval, setSelectedInterval) = useState("year")
    var (loading, setLoading) = useState(true)
    val userId = getCookie<String>(CookieNames.UserAccountId)?.replace("\"", "")
    val userName = getCookie<String>(CookieNames.UserName)?.replace("\"", "")
    val userEmail = getCookie<String>(CookieNames.UserEmail)?.replace("\"", "")
    val cardStyle: dynamic = js {
        width = "80%"
        height = "450px"
        margin = "8px"
        padding = "5px"
        backgroundColor =
            if (theme.palette.mode == PaletteMode.dark) Color("rgba(51, 51, 51, )") else Color("rgba(217, 217, 217)")
        color = if (theme.palette.mode == PaletteMode.dark) "#D9D9D9" else "#303030"
        borderRadius = "15px"
        display = "flex"
        flexDirection = "column"
        transition = "transform 0.3s"
        border = "solid 2.5px "
        borderColor = theme.palette.secondary.main
        marginTop = "45px"
        overflow = "hidden"
        fontSize = "20.px"

        sx = js {
            hover = js {
                transform = "scale(1.1)"
            }
        }
    }

    suspend fun handleGetPlans(planName: String?, interval: String) {
        setLoading(true)
        try {
            val planService = PlanService().getPlans(null,planName, interval)
            val combinedDataList = mutableListOf<CardData>()
            if (planService.isEmpty()) {
                setLoading(false)
                throw JsError("Plans not found")

            } else {
                planService.forEach { plan ->
                    try {
                        val product = plan.products?.firstOrNull()
                        val cardData = CardData(
                            title = plan.name,
                            description = plan.description,
                            price = plan.price ?: "0",
                            productId = product?.productId!!
                        )
                        combinedDataList.add(cardData)

                    } catch (error: Throwable) {
                        console.error("Error getting products for plan ${plan.name}", error)
                        setLoading(false)

                    }
                }

            }
            setCardDataList(combinedDataList)
            setLoading(false)


        } catch (error: Throwable) {
            console.error("Error getting plans", error)
            setLoading(false)

        } finally {
            setLoading(false)
        }
    }

    suspend fun handleSession(cardData: CardData) {
        setLoading(true)
        try {
            val checkoutSession = CheckoutSession(
                successUrl = SUCCESS_URL,
                mode = MODE,
                interval = selectedInterval,
                user = User(userId!!, userName!!, userEmail!!),
                stripeCustomerId = null
            )
            val response = SessionService().createSession(null,checkoutSession, cardData.productId)
            window.location.href = response.url
            setLoading(false)
        } catch (error: Throwable) {
            console.error("Error getting checkout url", error)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(listOf(dataLoaded), selectedInterval) {
        if (!dataLoaded) {
            GlobalScope.launch {
                handleGetPlans(selectedPlan, selectedInterval)
                setDataLoaded(true)
            }
        }
    }
    if (loading) {
        return@FC Grid {
            sx {
                justifyContent = JustifyContent.center
                marginTop = 320.px
                marginLeft = 650.px

            }
            CircularProgress {
                sx = js {
                    color =
                        if (theme.palette.mode == PaletteMode.dark) CircularProgressColor.primary else CircularProgressColor.secondary
                }
                size = 80
                thickness = 4
            }
        }
    }


    val handleSwitchChange: (String) -> Unit = { interval ->
        setSelectedInterval(interval)
        GlobalScope.launch {
            handleGetPlans(selectedPlan, interval)
        }
    }
    Grid {
        container = true
        spacing = responsive(2)
        sx {
            justifyContent = JustifyContent.center
            marginTop = 20.px

        }

        Grid {
            item = true
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                flexDirection = FlexDirection.column
            }

            Typography {
                variant = TypographyVariant.h3
                gutterBottom = true
                +("Subscription Plans")
            }
        }

        Grid {
            container = true
            spacing = responsive(2)
            sx {
                justifyContent = JustifyContent.end
                marginTop = 20.px

            }
            FormControlLabel {
                control = buildElement {
                    ToggleButtonGroup {
                        attrs.exclusive = true
                        attrs.value = selectedInterval
                        attrs.onChange = { _, value -> handleSwitchChange(value as String) }
                        ToggleButton {
                            attrs.value = "year"
                            attrs.style = js {
                                backgroundColor = if (selectedInterval == "year") "#AE0E30" else "#D9D9D9"
                                color = if (selectedInterval == "year") "white" else "black"

                            }
                            +"Year"
                        }
                        ToggleButton {
                            attrs.value = "month"
                            attrs.style = js {
                                backgroundColor = if (selectedInterval == "month") "#AE0E30" else "#D9D9D9"
                                color = if (selectedInterval == "month") "white" else "black"
                            }
                            +"Month"
                        }
                    }
                }
            }
        }





        Grid {
            container = true
            spacing = responsive(2)
            cardDataList?.forEachIndexed { index, cardData ->
                Grid {
                    item = true
                    key = index.toString()
                    sx {
                        width = 450.px
                        marginBottom = 20.px

                    }
                    Card {

                        sx = cardStyle
                        CardContent {
                            Typography {
                                gutterBottom = true
                                variant = TypographyVariant.h5
                                sx {
                                    fontSize = FontSize.xLarge
                                    marginTop = 5.pct
                                    textTransform = TextTransform.uppercase
                                    fontWeight = FontWeight.normal
                                }
                                +(cardData.title?.uppercase() ?: "")
                            }
                            Box {
                                Typography {
                                    sx {
                                        marginTop = 20.px
                                    }
                                    AttachMoney {}
                                    variant = TypographyVariant.h2
                                    sx {
                                        display = Display.flex
                                        alignItems = AlignItems.end
                                        flexDirection = FlexDirection.row
                                        justifyContent = JustifyContent.end
                                        marginLeft = 5.px
                                        color =
                                            if (theme.palette.mode == PaletteMode.dark) Color("#D9D9D9") else Color("#303030")
                                        fontSize = 16.px
                                        marginBottom = 200.px
                                    }
                                    +(cardData.price.toString())
                                }
                            }

                            Box {
                                Typography {
                                    sx {
                                        marginTop = 20.px
                                        color =
                                            if (theme.palette.mode == PaletteMode.dark) Color("#D9D9D9") else Color("#303030")

                                    }
                                    variant = TypographyVariant.body2
                                    Done {
                                        sx {
                                            color = Color("#AE0E30")
                                        }
                                    }
                                    sx {
                                        display = Display.flex
                                        alignItems = AlignItems.start
                                        flexDirection = FlexDirection.row
                                        justifyContent = JustifyContent.start
                                        marginLeft = 5.px
                                        marginTop = -180.px
                                        color =
                                            if (theme.palette.mode == PaletteMode.dark) Color("#D9D9D9") else Color("#303030")
                                        fontSize = 16.px
                                    }
                                    +(cardData.description)
                                }
                            }
                        }
                        Box {
                            sx {
                                display = Display.flex
                                justifyContent = JustifyContent.center
                                paddingTop = 220.px
                            }
                            Button {
                                variant = ButtonVariant.contained
                                color = ButtonColor.primary
                                sx {
                                    width = 200.px
                                    backgroundColor = Color("#AE0E30")
                                    color = Color("#d9d9d9")
                                    borderRadius = 9.px

                                }
                                onClick = {
                                    GlobalScope.launch {
                                        handleSession(cardData)
                                    }
                                }
                                +"Get Started"
                            }
                        }
                    }
                }
            }
        }
    }


}


fun RBuilder.cardGrid() = child(CardShowcase) {

}




