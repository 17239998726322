package tech.gensert.portal.common.model

import kotlinx.serialization.Serializable

@Serializable
actual data class Product(
    actual var  name: String? = null,
    actual var description: String? = null,
    actual var categoryName:String? = null,
    actual var activeStatus:Boolean? = false,
    actual var productId:String? = null
) : BaseModel()