
import emotion.css.css
import mui.icons.material.*
import mui.material.*
import mui.material.Badge
import mui.material.Input
import mui.system.Box
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import styled.css
import tech.gensert.portal.web.pages.Area
import tech.gensert.portal.web.showcase.HamburgerMenuShowcase
import tech.gensert.portal.web.showcase.Searchbar
import web.cssom.*
import web.cssom.atrule.maxWidth



val Header = FC {

    val (isSearchVisible, setSearchVisible) = useState(false)
    val (isAvatarMenuVisible, setAvatarMenuVisible) = useState(false)



    Box {
        sx {
            gridArea = Area.Content
            marginTop = -60.px
        }
        component = ReactHTML.main

        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.end
                alignItems = AlignItems.center
                marginTop = 15.px
            }
            Searchbar{}

            Badge {
                sx {
                    //marginRight = 5.px
                }
                color = BadgeColor.primary
                //badgeContent = ReactNode("")
                onClick = { setAvatarMenuVisible(!isAvatarMenuVisible) }
                Notifications()
            }
            HamburgerMenuShowcase {}
        }
    }
}















