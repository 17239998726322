package tech.gensert.portal.common.model.config


import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

object HttpClientProvider {
    val httpClient: HttpClient by lazy {
        HttpClient(Js) {
            install(ContentNegotiation) {
                json(Json {
                    prettyPrint = true
                    isLenient = true
                })
            }

            defaultRequest {
                val accessToken = getCookie<String>(CookieNames.Tokens)
                val tokenValue = accessToken?.replace("\"", "")
                accessToken?.let { header(HttpHeaders.Authorization, "Bearer $tokenValue") }
                header(HttpHeaders.Accept, "application/json")
                header(HttpHeaders.ContentType, "application/json")
            }
        }
    }
}
suspend fun optionsRequest(url: String, method: HttpMethod): HttpResponse {
    return HttpClientProvider.httpClient.options(url) {
        header(HttpHeaders.AccessControlRequestMethod, method.value)
        header(HttpHeaders.AccessControlRequestHeaders, HttpHeaders.Authorization)
    }.apply{}
}

suspend inline fun <reified T> makeRequest(
    url: String,
    method: HttpMethod,
    body: Any? = null,
    parameters: Map<String, String> = emptyMap(),
    preflight: Boolean = true
): T {
    if (preflight) {
        optionsRequest(url, method)
    }

    val response: HttpResponse = when (method) {
        HttpMethod.Get -> HttpClientProvider.httpClient.get(url) {
            parameters.forEach { (key, value) -> parameter(key, value) }
        }
        HttpMethod.Post -> HttpClientProvider.httpClient.post(url) {
            contentType(ContentType.Application.Json)
            parameters.forEach { (key, value) -> parameter(key, value) }
            body?.let { setBody(it) }
        }
        HttpMethod.Put -> HttpClientProvider.httpClient.put(url) {
            contentType(ContentType.Application.Json)
            parameters.forEach { (key, value) -> parameter(key, value) }
            body?.let { setBody(it) }
        }
        HttpMethod.Delete -> HttpClientProvider.httpClient.delete(url) {
            parameters.forEach { (key, value) -> parameter(key, value) }
        }
        else -> throw UnsupportedOperationException("HTTP method $method not supported")
    }

    return Json.decodeFromString(response.bodyAsText())
}