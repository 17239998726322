package tech.gensert.portal.common.model

import kotlinx.serialization.Serializable

@Serializable
actual data class User(
    actual var userId: String,
    actual var name: String,
    actual var email: String,
) : BaseModel()
