package tech.gensert.portal.web.showcase.utils

import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import react.router.useNavigate
import react.useState
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.common.model.config.getCookie
import tech.gensert.portal.web.config.cookies.storeUserAccount
import tech.gensert.portal.web.showcase.reusables.jsObject
import tech.gensert.portal.web.wrappers.useMsal

fun useTokenManager(): TokenManager {
    val context = useMsal()
    val instance = context.instance
    val navigate = useNavigate()
    val urlParams = window.location.search
    val hint = getCookie<String>(CookieNames.gt_login_hint)?.replace("\"", "")

    val (msalReady, setMsalReady) = useState(false)

    react.useEffectOnce {
        instance.initialize()
            .then {
                console.log("✅ MSAL is now initialized")
                setMsalReady(true)
            }
            .catch { error ->
                console.error("❌ MSAL initialization failed: ", error)
            }

        instance.handleRedirectPromise()
            .then { authResult ->
                if (authResult != null) {
//                    console.log("🔑 Redirect auth successful:", authResult)
                    storeUserAccount(authResult)
                    sessionStorage.setItem("msalActiveAccount", JSON.stringify(authResult.account))
                    instance.setActiveAccount(authResult.account)
                    if (window.location.pathname == "/") {
                        navigate("/services")
                    }
                }
            }
            .catch { error ->
                console.error("❌ Error during redirect handling:", error)
            }
    }

    val activeAccount = instance.getActiveAccount()

    val tokenRequest = jsObject<dynamic> {
        this.loginHint = hint
    }
    val loginRequest = jsObject<dynamic> {
        this.account = activeAccount
    }

    fun login(onSuccess: (String) -> Unit = {}, onError: (Throwable) -> Unit = {}) {
        instance.loginRedirect(loginRequest)
            .catch { error ->
                console.error("❌ Error during login redirect: ", error)
                onError(error)
            }
    }

    fun acquireTokenSilently(onSuccess: (String) -> Unit, onError: (Throwable) -> Unit) {
        instance.ssoSilent(tokenRequest)
            .then { authResult ->
                storeUserAccount(authResult)
                instance.setActiveAccount(authResult.account)
                onSuccess(authResult.accessToken)

                if (window.location.pathname == "/") {
                    navigate("/services")
                }
            }
            .catch { error ->
                console.log("⚠️ Silent login failed, prompting user for login...", error)

                login(onSuccess, onError)
            }
    }

    fun getToken(onSuccess: (String) -> Unit, onError: (Throwable) -> Unit) {
        acquireTokenSilently(
            onSuccess = {
                onSuccess(it)
                if (window.location.pathname == "/") {
                    navigate("/services")
                }
            },
            onError = {
                console.log("⚠️ Silent token acquisition failed: $it")
                login(onSuccess, onError)
            }
        )
    }

    fun isAuthenticated(): Boolean {
        return sessionStorage.getItem("msalActiveAccount") != null
    }

    react.useEffectOnce {
        if (hint != null && !isAuthenticated()) {
            console.log("🔍 Detected login_hint: $hint, attempting silent authentication")
            acquireTokenSilently({}, { console.log("❌ Silent authentication failed") })
        }
    }

    return TokenManager(
        getToken = ::getToken,
        login = ::login,
        isAuthenticated = ::isAuthenticated
    )
}

data class TokenManager(
    val getToken: (onSuccess: (String) -> Unit, onError: (Throwable) -> Unit) -> Unit,
    val login: (onSuccess: (String) -> Unit, onError: (Throwable) -> Unit) -> Unit,
    val isAuthenticated: () -> Boolean
)
