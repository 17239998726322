package tech.gensert.portal.web.routes

import ErrorPage
import js.core.jso
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.dom.createBrowserRouter
import react.router.useNavigate
import react.useState
import remix.run.router.LoaderFunction
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.common.model.config.getCookie
import tech.gensert.portal.web.pages.LandingPageShowcase
import tech.gensert.portal.web.pages.LogoutPage
import tech.gensert.portal.web.pages.Page
import tech.gensert.portal.web.showcase.PaymentAndSubscriptionShowcase
import tech.gensert.portal.web.showcase.ServicesCard
import tech.gensert.portal.web.showcase.SettingsShowcase
import tech.gensert.portal.web.showcase.utils.useTokenManager
import tech.gensert.portal.web.wrappers.useMsal
import kotlin.js.Promise

val LoaderComponent: FC<Props> = FC {
    div {
        +"Loading..."
    }
}
val MATERIAL_SHOWCASES: Array<out Showcase> = arrayOf(
    Showcase("services", "Services", ServicesCard),
    Showcase("subscriptions", "Subscriptions", PaymentAndSubscriptionShowcase),
    Showcase("settings", "Settings", SettingsShowcase),
)
val ShowcaseMaterialLoader: LoaderFunction = { args ->
    val showcaseId = args.params["showcaseId"]
    val showcase = MATERIAL_SHOWCASES.find { it.key == showcaseId }
    Promise.resolve(showcase ?: throw IllegalArgumentException("Showcase not found for ID: $showcaseId"))
}

external interface ProtectedRouteProps : Props {
    var component: FC<Props>
}
val ProtectedRoute: FC<ProtectedRouteProps> = FC { props ->
    val navigate = useNavigate()
    val context = useMsal()
    val urlParams = window.location.search
    val hint = getCookie<String>(CookieNames.gt_login_hint)?.replace("\"", "")
    val (isCheckingAuth, setIsCheckingAuth) = useState(true)
    val tokenManager = useTokenManager()

    react.useEffectOnce {
        window.setTimeout({
            val activeAccount = sessionStorage.getItem("msalActiveAccount")
//            console.log("Checking authentication after delay. Account:", activeAccount)

            if (!tokenManager.isAuthenticated()) {
                if (hint != null) {
//                    console.log("No active account, but login_hint is present. Attempting silent login.")
                    tokenManager.getToken(
                        { setIsCheckingAuth(false) },
                        {
//                            console.log("Silent login failed, redirecting to login page.")
                            navigate("/")
                        }
                    )
                } else {
//                    console.log("No active account and no login_hint, redirecting to login.")
                    navigate("/")
                }
            } else {
//                console.log("User already authenticated, proceeding.")
                setIsCheckingAuth(false)
                val currentPath = window.location.pathname
                if (currentPath == "/") {
                    navigate("/services")
                }
            }
        }, 500)
    }
    if (isCheckingAuth) {
        return@FC LoaderComponent()
    }
    props.component()
}


val ProtectedServices: FC<Props> = FC {
    ProtectedRoute {
        component = ServicesCard
    }
}
val ProtectedShowcase: FC<Props> = FC {
    ProtectedRoute {
        component = ShowcaseMaterial
    }
}


val PageLoader: LoaderFunction = { _ ->
    Promise.resolve(MATERIAL_SHOWCASES)
}

val appRouter = createBrowserRouter(
    arrayOf(
        jso {
            path = "/"
            loader = PageLoader
            Component = LandingPageShowcase
            ErrorBoundary = ErrorPage
        },

        jso {
            path = "/"
            loader = PageLoader
            Component = Page
            ErrorBoundary = ErrorPage
            children = arrayOf(
                jso {
                    index = true
                    path = "services"
                    loader = PageLoader
                    Component = ProtectedServices
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = ":showcaseId"
                    loader = ShowcaseMaterialLoader
                    Component = ProtectedShowcase
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = "/logout"
                    Component = LogoutPage
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = "*"
                    Component = ErrorPage
                }

            )
        }
    )
)