package tech.gensert.portal.web.config

import kotlinx.browser.document
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.common.model.config.UserData
import tech.gensert.portal.web.wrappers.AccountInfo

fun storeUserAccount(account: AccountInfo) {
    val data= UserData (id=account.idTokenClaims.oid!!, name=account.username, email= account.idTokenClaims.emails!![0])
    val idCookie = "${CookieNames.UserAccountId}=${Json.encodeToString(account.idTokenClaims.oid!!)}"
    val emailCookie = "${CookieNames.UserEmail}=${Json.encodeToString( account.idTokenClaims.emails!![0])}"
    val nameCookie = "${CookieNames.UserName}=${Json.encodeToString( account.username)}"
    document.cookie = idCookie
    document.cookie = emailCookie
    document.cookie = nameCookie
    val tokenCookie = "${CookieNames.Tokens}=${Json.encodeToString(account.idToken)}"
    document.cookie = tokenCookie
}
