package tech.gensert.portal.web.showcase

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px


val HomeShowcase = FC<Props> {


    div {
        css {
        display = Display.flex
        justifyContent = JustifyContent.start
        alignItems = AlignItems.start
        gap = 8.px
    }
        h1 {
            +"Hello"
        }

        }

    p {
        +"Coming Soon."

    }

       }
    
