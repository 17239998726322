package tech.gensert.portal.web.showcase

import react.FC
import react.Props
import react.router.useLoaderData

val ShowcaseMaterial = FC<Props> {
    val ShowcaseComponent = useLoaderData()
        .unsafeCast<Showcase>()
        .component
    ShowcaseComponent()
}
