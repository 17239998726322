package tech.gensert.portal.common.model

import kotlinx.serialization.Serializable

@Serializable
actual data class Plan(
    actual var  name: String?=null,
    actual var description: String?=null,
    actual var price: String?= null,
    actual var products: List<Product>?=null
) : BaseModel()