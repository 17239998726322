package tech.gensert.portal.web.showcase.reusables

import kotlinx.browser.window

object WebReusables {
    private val location = window.location.origin

    var SUCCESS_URL = "$location/subscriptions"
    const val MODE = "subscription"
}

inline fun <T> jsObject(builder: T.() -> Unit): T {
    val obj = js("{}").unsafeCast<T>()
    return obj.apply(builder)
}