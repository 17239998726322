package tech.gensert.portal.web.components


import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import web.cssom.Cursor
import web.cssom.NamedColor.Companion.gray
import web.cssom.NamedColor.Companion.white
import web.cssom.px
import web.cssom.rgb

external interface ButtonProps : Props {

    var label: String
    var onClick: () -> Unit
}

val Button = FC<ButtonProps> { props ->
    button {
        css {
            fontSize = 16.px
            padding = 10.px
            backgroundColor = gray
            width = 200.px
            color = white
            cursor = Cursor.pointer
            borderRadius = 5.px
            hover {
                backgroundColor = rgb(174, 14, 48)
            }
        }
        +props.label
        onClick = { _ -> props.onClick() }
    }
}