package tech.gensert.portal.web.pages

import web.cssom.px

object Sizes {
    object Header {
        val Height = 54.px
    }

    object Sidebar {
        val Width = 300.px
    }
}
