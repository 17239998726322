package tech.gensert.portal.web.pages

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import tech.gensert.portal.web.components.Button
import tech.gensert.portal.web.config.cookies.storeUserAccount
import tech.gensert.portal.web.wrappers.useMsal
import web.cssom.*


val LandingPageShowcase = FC<Props> {


    val context = useMsal()
    val instance = context.instance
    val navigate = useNavigate()
    /*
        We can certainly do better here by relying on instance.getActiveAccounts()
        For some reason, it doesn't work as expected.
 */

    val account = context.accounts.firstOrNull()
    val status = context.inProgress
    if (account != null) {
        storeUserAccount(account)
        navigate("/services")
        return@FC
    }


    val handleLogin = {
        instance.loginRedirect().catch { error ->
            console.error("Error when redirecting while status $status : $error")
        }
    }


    div {
        css {
            backgroundColor = rgb(48, 48, 48)
            color = rgb(240, 240, 240)
            flexDirection = FlexDirection.column
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            minHeight = 100.vh
            overflow = Overflow.hidden

            media("(max-width: 768px)") {
                flexDirection = FlexDirection.column
            }

        }
        img {
            src = "Icon-log.png"
            alt = "Logo"
            css {

                position = Position.absolute
                top = 20.px
                left = 20.px
                width = 50.px
                height = 50.px

                media("(max-width: 768px)") {
                    width = 50.px
                    height = 50.px
                    top = 10.px
                    left = 10.px
                }
            }
        }


        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
                textAlign = TextAlign.left
                padding = 20.px
                marginTop = 20.px
                overflow = Overflow.hidden;
                borderRadius = 20.px;
                width = 700.px;

                media("(max-width: 768px)") {
                    width = 90.vw
                    position = Position.absolute


                }
            }

            div {
                css {
                    display = Display.flex
                    alignItems = AlignItems.start
                    flexDirection = FlexDirection.column
                    textAlign = TextAlign.left
                    zIndex = integer(1)


                }
                h1 {
                    css{

                        fontSize =48.px
                        media("(max-width: 768px)") {
                            fontSize = 24.px
                        }
                    }

                    +" Welcome "

                }
                h1 {
                    css{
                        marginBottom =24.px
                        media("(max-width: 768px)") {
                            fontSize = 18.px
                        }
                    }
                    +" to GENSERT TECHNOLOGIES"
                }
                p {
                    css{
                        fontSize =20.px
                    }

                    +" To provide independent IT Advisory and Solutions through our ventures in the Tech domain,"
                    +" we aggregate talents and skills to help our clients master their digital business’\u200B needs."
                    +" To provide independent IT Advisory and Solutions through our ventures in the Tech domain,"
                    +" we aggregate talents and skills to help our clients master their digital business’\u200B needs."
                }

                div {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        gap = 10.px
                        marginTop = 50.px
                        borderRadius = 13.px

                        zIndex = integer(1)
                    }

                    Button {
                        label = "Sign In"
                        onClick = { handleLogin() }
                    }

                    Button {
                        label = "Sign Up"
                        onClick = { handleLogin() }
                    }
                }
            }
        }

        div {
            css {
                position = Position.absolute
                bottom = 0.px
                left = -100.px
                width = 370.px
                height = 250.px
                backgroundColor = rgb(174, 14, 48)
                borderRadius = 630.px
                overflow = Overflow.hidden

                zIndex = integer(2)
                media("(max-width: 768px)") {
                    height = 200.px
                }
            }
        }


        div {
            css {
                position = Position.absolute
                top = -150.px
                right = -10.px
                width = 350.px
                height = 750.px
                backgroundColor = rgb(174, 14, 48)
                borderRadius = 50.pct
                transform = rotate(325.deg)
                overflow = Overflow.hidden
                zIndex = integer(2)
                media("(max-width: 768px)") {
                    right = -200.px
                }
            }
        }
        div {
            css {
                position = Position.absolute
                top = -160.px
                right = -110.px
                width = 350.px
                height = 750.px
                backgroundColor = rgb(56, 53, 53)
                borderRadius = 50.pct;
                transform = rotate(335.deg)
                zIndex = integer(2)
                media("(max-width: 768px)") {
                    right = -230.px
                }


            }
        }

    }
}






