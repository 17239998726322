package tech.gensert.portal.web.pages
import kotlinx.css.label
import mui.icons.material.Logout
import mui.icons.material.Notifications
import mui.material.*
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.Outlet
import tech.gensert.portal.web.wrappers.useMsal
import web.cssom.*
import web.prompts.alert

val Content = FC<Props> {

  
    Box {

        sx {
            gridArea = Area.Content
            padding = 30.px

        }
        component = ReactHTML.main

   

        Outlet()
    }
}









