package tech.gensert.portal.common.model.config

@JsModule("./buildEnv.js")
external val buildEnv: dynamic

object BuildEnv {
    val backendUrl = buildEnv.BACKEND_URL as String
    val clientId = buildEnv.CLIENT_ID as String
    val tenantName = buildEnv.TENANT_NAME as String
    val backendClientId = buildEnv.BACKEND_CLIENT_ID as String
}