

import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.TypographyOptions
import mui.material.styles.TypographyVariant
import mui.material.styles.createTheme
import web.cssom.atrule.maxWidth
import web.cssom.integer
import web.cssom.px
import web.cssom.rem

private val TYPOGRAPHY_OPTIONS = TypographyOptions {
    fontWeight = integer(400)

    TypographyVariant.h6 {
        fontSize = 1.5.rem

        media(maxWidth(599.px)) {
            fontSize = 1.25.rem
        }
    }
}

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.light
                primary = jso {
                    main = "#D9D9D9"
                }
                secondary = jso{
                    main = "#AE0E30"
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )

    val Dark = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.dark
                primary = jso {
                    main = "#303030"
                }
                secondary = jso{
                    main = "#AE0E30"
                }

            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )
}
