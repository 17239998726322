package tech.gensert.portal.web.config.cookies

import kotlinx.browser.document
import kotlinx.browser.window
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.web.wrappers.AuthenticationResult

fun getDomain(): String {
    val hostname = window.location.hostname
    return when {
        hostname == "localhost" -> ""
        else -> {
            val parts = hostname.split(".")
            if (parts.size >= 3) {
                ".${parts[parts.size - 2]}.${parts.last()}"
            } else {
                hostname
            }
        }
    }
}

fun storeUserAccount(authenticationResult: AuthenticationResult) {
    val domain = getDomain()
    val isLocalhost = window.location.hostname == "localhost"
    val domainAttribute = if (domain.isNotEmpty()) "; domain=$domain" else ""
    val secureAttribute = if (isLocalhost) "" else "; Secure"
    val sameSiteAttribute = if (isLocalhost) "Lax" else "None"
    val idCookie =
        "${CookieNames.UserAccountId}=${authenticationResult.account.idTokenClaims.oid}; path=/; $secureAttribute; SameSite=$sameSiteAttribute$domainAttribute"
    val emailCookie =
        "${CookieNames.gt_login_hint}=${authenticationResult.account.username}; path=/; $secureAttribute; SameSite=$sameSiteAttribute$domainAttribute"
    val nameCookie =
        "${CookieNames.UserName}=${authenticationResult.account.name}; path=/; $secureAttribute; SameSite=$sameSiteAttribute$domainAttribute"
    val tokenCookie =
        "${CookieNames.Tokens}=${authenticationResult.accessToken}; path=/; $secureAttribute; SameSite=$sameSiteAttribute$domainAttribute"
    document.cookie = idCookie
    document.cookie = emailCookie
    document.cookie = nameCookie
    document.cookie = tokenCookie
}

fun storeTheme(theme: String) {
    val domain = getDomain()
    val isLocalhost = window.location.hostname == "localhost"
    val domainAttribute = if (domain.isNotEmpty()) "; domain=$domain" else ""
    val secureAttribute = if (isLocalhost) "" else "; Secure"
    val sameSiteAttribute = if (isLocalhost) "Lax" else "None"

    document.cookie =
        "theme=$theme; path=/; max-age=31536000; $secureAttribute; SameSite=$sameSiteAttribute$domainAttribute"
}
