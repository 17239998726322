package tech.gensert.portal.common.model

import io.ktor.http.*
import tech.gensert.portal.common.config.Endpoint
import tech.gensert.portal.common.model.config.BuildEnv
import tech.gensert.portal.common.model.config.makeRequest


actual class PlanService {
    actual suspend fun getPlans(authToken: String?, planName: String?, subscriptionType: String?): List<Plan> {
        val url = buildString {
            append("${BuildEnv.backendUrl}${Endpoint.PLAN_URL}?")
            planName?.let { append("planName=$it&") }
            subscriptionType?.let { append("subscriptionType=$it") }
        }
        return makeRequest<List<Plan>>(url, HttpMethod.Get)

    }

}