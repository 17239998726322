package tech.gensert.portal.common.model.config

import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

object HttpClientProvider {
    val httpClient: HttpClient by lazy {
        HttpClient(Js) {
            install(ContentNegotiation) {
                json(Json {
                    prettyPrint = true
                    isLenient = true
                    ignoreUnknownKeys = true
                })
            }
            defaultRequest {
                val accessToken = getCookie<String>(CookieNames.Tokens)
                val tokenValue = accessToken?.replace("\"", "")
                accessToken?.let { header(HttpHeaders.Authorization, "Bearer $tokenValue") }
            }
        }
    }
}

suspend inline fun <reified T> makeRequest(
    url: String,
    method: HttpMethod,
    body: Any? = null,
    parameters: Map<String, String> = emptyMap(),
): T {
    val response: HttpResponse = HttpClientProvider.httpClient.request(url) {
        this.method = method
        contentType(ContentType.Application.Json)
        parameters.forEach { (key, value) -> parameter(key, value) }
        body?.let { setBody(it) }
    }

    return Json.decodeFromString(response.bodyAsText())
}
