
import mui.material.BottomNavigation
import mui.material.BottomNavigationAction
import mui.system.sx
import react.FC
import react.ReactNode

import react.dom.html.ReactHTML

import web.cssom.Color
import web.cssom.Position
import web.cssom.pct
import web.cssom.px
import kotlin.text.Typography.nbsp


val Footer = FC {
    BottomNavigation {
        sx {
            position = Position.fixed
            bottom = 0.px
            width = 100.pct

            color = Color("#fff") 
        }

        component = ReactHTML.footer

        showLabels = true
        value = 1

        BottomNavigationAction {
            label = ReactNode("Gensert${nbsp}Tech")

        }
    }
}