package tech.gensert.portal.web.showcase

import Themes
import emotion.react.css
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import mui.icons.material.Logout
import mui.icons.material.Menu
import mui.icons.material.PersonOutline
import mui.icons.material.Tune
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.router.dom.Link
import react.router.useNavigate
import react.useState
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.common.model.config.getCookie
import tech.gensert.portal.web.theme.Colors.BACKGROUND_DEFAULT_LIGHT
import tech.gensert.portal.web.theme.Colors.BLACK
import tech.gensert.portal.web.theme.Colors.GHOST_WHITE
import tech.gensert.portal.web.theme.Colors.GREY
import tech.gensert.portal.web.theme.Colors.PRIMARY_DARK
import tech.gensert.portal.web.theme.Colors.PRIMARY_MAIN
import useTheme
import web.cssom.*
import web.html.HTMLDivElement

fun clearCookies() {
    val cookiesToClear = arrayOf(
        CookieNames.UserAccountId,
        CookieNames.gt_login_hint,
        CookieNames.UserName,
        CookieNames.Tokens
    )
    cookiesToClear.forEach { cookieName ->
        window.document.asDynamic().cookie = "$cookieName=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
    }
}
val HamburgerMenuShowcase = FC<Props> {

    val navigate= useNavigate()
    fun handleLogout() {
        console.log("Navigating to logout page")
        clearCookies()
        sessionStorage.clear()
        console.log("Logging out...")
        navigate("/logout")
    }

    val (isMenuOpen, setMenuOpen) = useState(false)
    val theme = useTheme()
    val username = getCookie<String>(CookieNames.UserName)?.replace("\"", "")
    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.spaceBetween
            alignItems = AlignItems.center
            padding = 10.px
        }
        Box {
            sx {
                fontSize = 26.px
                marginTop = 8.px
            }
            onClick = { event: react.dom.events.MouseEvent<HTMLDivElement, *> ->
                setMenuOpen(!isMenuOpen)
            }
            Menu {}
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                position = Position.absolute
                top = 60.px
                right = 3.px
                backgroundColor =
                    if (theme.palette.mode == PaletteMode.dark) Color(PRIMARY_DARK) else Color(GREY)
                padding = 16.px
                borderRadius = 8.px
                width = 200.px
                visibility = if (isMenuOpen) Visibility.visible else Visibility.hidden
                zIndex = 1000.asDynamic().unsafeCast<ZIndex>()
            }
            val closeMenu = {
                setMenuOpen(false)
            }
            Box {
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                }
                Avatar {
                    sx {
                        marginBottom = 10.px
                        marginRight = 7.px
                    }
                }
                Typography {
                    variant = TypographyVariant.subtitle1
                    sx {
                        color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
                    }
                    +username
                }
            }
            Divider {}
            Box {
                sx {
                    hover {
                        textDecoration = None.none
                        backgroundColor = Color(PRIMARY_MAIN)
                        color = Color(GHOST_WHITE)
                        borderRadius = 8.px
                        border = Border(1.px, LineStyle.solid, Color(PRIMARY_MAIN))
                    }
                }
                Link {
                    to = "/personal-information"
                    onClick = { closeMenu }
                    css {
                        display = Display.flex
                        alignItems = AlignItems.center
                        marginBottom = 8.px
                        width = 100.pct
                        marginTop = 8.px
                        textDecoration = None.none
                        color = Color.currentcolor
                    }
                    PersonOutline {
                        sx {
                            marginRight = 9.px
                        }
                    }
                    Typography {
                        variant = TypographyVariant.subtitle1
                        sx {
                            color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
                        }
                        +"Profile"
                    }
                }
            }
            Divider {}
            Box {
                sx {
                    hover {
                        textDecoration = None.none
                        backgroundColor = Color(PRIMARY_MAIN)
                        color = Color(GHOST_WHITE)
                        borderRadius = 8.px
                        border = Border(1.px, LineStyle.solid, Color(PRIMARY_MAIN))
                    }
                }
                Link {
                    to = "/settings"
                    css {
                        display = Display.flex
                        alignItems = AlignItems.center
                        marginBottom = 8.px
                        marginTop = 8.px
                        color = Color.currentcolor
                        textDecoration = None.none
                        hover {
                            textDecoration = None.none
                            backgroundColor = Color(PRIMARY_MAIN)
                        }
                        focus {
                            outline = None.none
                        }
                    }
                    Tune {
                        sx {
                            marginRight = 9.px
                            textDecoration = TextDecoration.underline
                        }
                    }
                    Typography {
                        variant = TypographyVariant.subtitle1
                        sx {
                            color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
                        }
                        +"Settings"
                    }
                }
            }
            Divider {}
            Box {
                sx {
                    hover {
                        textDecoration = None.none
                        backgroundColor = Color(PRIMARY_MAIN)
                        color = Color(GHOST_WHITE)
                        borderRadius = 8.px
                        border = Border(1.px, LineStyle.solid, Color(PRIMARY_MAIN))
                    }
                }
                Link {
                    css {
                        marginTop = 8.px
                        display = Display.flex
                        alignItems = AlignItems.center
                        color = Color.currentcolor
                        textDecoration = None.none
                        marginBottom = 8.px
                    }
                    onClick = {
                        handleLogout()
//                        handleLogout().catch { error ->
//                            console.error("Error logging out", error)
//                            alert("Cannot log out. Please try again later.")
//                        }
                    }
                    Logout {
                        sx {
                            marginRight = 9.px
                        }
                    }
                    Typography {
                        variant = TypographyVariant.subtitle1
                        sx {
                            color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
                        }
                        +"Logout"
                    }

                }
            }
        }
    }
}

