
import emotion.react.css
import emotion.styled.styled
import js.uri.decodeURIComponent
import js.uri.encodeURIComponent
import mui.icons.material.*
import mui.material.*
import mui.material.List
import mui.material.Mui.Companion.selected
import mui.material.styles.TypographyVariant
import mui.system.Box
import mui.system.sx
import react.*
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.img
import react.router.dom.NavLink
import react.router.useLoaderData
import tech.gensert.portal.web.pages.Area
import tech.gensert.portal.web.pages.Sizes
import tech.gensert.portal.web.routes.useShowcaseIdParam
import tech.gensert.portal.web.showcase.Showcase
import web.cssom.*


val Sidebar = FC<Props> {
    val isMobile = useIsMobile()
    var isOpen by useState(false)
    val theme = useTheme()
    val setTheme = useSetTheme()

    Box {

        if (!isMobile) {
            sx {
                gridArea = Area.Sidebar
            }
        }

        component = ReactHTML.nav

        if (isMobile) {
            SwipeableDrawer {
                anchor = DrawerAnchor.left
                open = isOpen
                onOpen = { isOpen = true }
                onClose = { isOpen = false }

                ShowcaseList()
            }

            SpeedDial {
                sx {
                    position = Position.absolute
                    bottom = 80.px
                    left = 16.px
                }

                ariaLabel = "Menu"

                icon = Slideshow.create()
                onClick = { isOpen = !isOpen }
            }
        } else {
            Drawer {
                variant = DrawerVariant.permanent
                anchor = DrawerAnchor.left

                ShowcaseList()
            }
        }
    }
}

private val ShowcaseList = FC {
    val showcases = useLoaderData().unsafeCast<Array<out Showcase>>()
    val theme = useTheme()
    val setTheme = useSetTheme()

    val (_, setSelectedLink) = useState(encodeURIComponent("Home Page"))

    Box {

        sx{

            backgroundColor = Color("#303030")
        }
        Toolbar {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
            }

            img {
                alt = "Gensert Tech Logo"
                src = "icon-log.png"

                css {

                    height = 45.px
                    width = 45.px
                    marginRight = 10.px
                }
            }


            Typography {
                component = ReactHTML.div
                variant = TypographyVariant.h6
                noWrap = true
                +"Gensert Tech"
            }


        }
        sx {
            gridArea = Area.Sidebar
        }

        component = ReactHTML.nav
        Toolbar()

        List {
            sx {
                width = Sizes.Sidebar.Width
                marginTop = -50.px


            }


        }
        showcases.forEachIndexed { index, showcase ->
            val link = encodeURIComponent(showcase.key)
            val selectedLink = decodeURIComponent(useShowcaseIdParam())


            LinkButton {
                to = link

                ListItemButton {
                    // var selectedLink by useState(decodeURIComponent(useShowcaseIdParam()) == showcase.key)
                    sx {
                        textDecoration = None.none
                        backgroundColor = if (selectedLink == showcase.key) Color("#AE0E30") else Color("#fffff")
                        hover {
                            backgroundColor = Color("#AE0E30")
                            color = rgb(255, 255, 255)

                        }
                        selected {
                            backgroundColor = Color("#AE0E30")
                            color = Color("#AE0E30")
                            borderRadius = 10.px

                            hover {

                                backgroundColor = Color("#AE0E30")
                                color = rgb(255, 255, 255)
                                borderRadius = 13.px
                            }
                        }

                    }
                    onClick = { _ ->
                        setSelectedLink(link)
                    }
                    val isSelected = selectedLink == showcase.key
                    ListItemIcon {
                        when (showcase.name) {
                            "Home Page" -> Home()
                            "Personal Information" -> PersonOutline()
                            "Privacy and Security" -> LockOpen()
                            "Services" -> Dvr()
                            "Subscriptions" -> CreditCard()
                            "Settings" -> Tune()
                            "Help" -> Help()
                        }

                        sx {
                            color = if (isSelected) Color("#ffffff") else if (theme == Themes.Dark) rgb(255, 255, 255) else Color.currentcolor
                            width = 24.px
                            height = 24.px
                            marginRight = 8.px
                        }
                    }

                    ListItemText {
                        primary = ReactNode(showcase.name)
                        sx {
                            color = if (isSelected) Color("#ffffff") else Color.currentcolor
                            borderRadius = 13.px
                        }
                    }
                }
            }

            Tooltip {
                title = ReactNode("Theme")
                sx {

                    position = Position.fixed
                    bottom = 16.px
                    left = 120.px



                    color = rgb(174, 14, 48)
                }

                Switch {

                    // icon = WbSunny
                    // checkedIcon = NightsStay

                    ariaLabel = "theme"
                    checked = theme == Themes.Dark

                    onChange = { _, checked ->
                        setTheme(if (checked) Themes.Dark else Themes.Light)
                    }
                }
            }

        }
    }
}



private val LinkButton = NavLink.styled {
    textDecoration = None.none
    color = Color.currentcolor
    borderRadius = 13.px
}
private val SeparatorLine = FC {
    Divider {
        sx {
            marginTop = 2.px
            marginBottom = 2.px
            marginLeft = 16.px
            marginRight = 16.px
            backgroundColor = Color("#AE0E30")
            border = 9.px
        }
    }
}