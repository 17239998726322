import react.FC
import react.create
import react.router.RouterProvider
import tech.gensert.portal.web.config.msalConfig
import tech.gensert.portal.web.routes.appRouter
import tech.gensert.portal.web.wrappers.MsalProvider
import tech.gensert.portal.web.wrappers.PublicClientApplication


private val msalInstance = PublicClientApplication(msalConfig)

val App = FC {
    MsalProvider {
        instance = msalInstance
        children = ThemeModule.create {
            RouterProvider {
                router = appRouter
            }
        }
    }
}