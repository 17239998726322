package tech.gensert.portal.common.model.config

import kotlinx.browser.document

fun <T> getCookie(cookieName: CookieNames): T? {
    val cookies = document.cookie.split(';')
    for (cookie in cookies) {
        val trimmedCookie = cookie.trim()
        if (trimmedCookie.startsWith("${cookieName.name}=")) {
            return trimmedCookie.substring(cookieName.name.length + 1) as T
        }
    }
    return null
}