package tech.gensert.portal.web.showcase

import emotion.react.css
import mui.icons.material.*
import mui.material.Avatar
import mui.material.Box
import mui.material.Divider
import mui.material.PaletteMode
import mui.system.sx
import react.FC
import react.Props
import react.router.dom.Link
import react.useState
import web.cssom.*
import web.html.HTMLDivElement
import tech.gensert.portal.web.wrappers.useMsal
import useSetTheme
import useTheme
import web.cssom.Visibility
import web.prompts.alert


val HamburgerMenuShowcase = FC<Props> {
    val context = useMsal()
    val instance = context.instance
    val handleLogout = {
        instance.logoutRedirect()
    }
    val (isMenuOpen, setMenuOpen) = useState(false)
    val theme = useTheme()
    val setTheme = useSetTheme()

    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.spaceBetween
            alignItems = AlignItems.center
            padding = 10.px

        }

        Box {
            sx {
                fontSize = 26.px
                marginTop = 8.px
            }

            onClick = { event: react.dom.events.MouseEvent<HTMLDivElement, *> ->
                setMenuOpen(!isMenuOpen)
            }
            Menu {}
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                position = Position.absolute
                top = 60.px
                right = 3.px
                backgroundColor = if (theme.palette.mode == PaletteMode.dark) Color("rgba(51, 51, 51, 0.4)") else Color("rgba(217, 217, 217, 0.2)")
                padding = 16.px
                borderRadius = 8.px
                visibility = if (isMenuOpen) Visibility.visible else Visibility.hidden

            }
            val closeMenu = {
                setMenuOpen(false)
            }
            Box{
                sx{
                    display = Display.flex
                    alignItems = AlignItems.center

                }
                Avatar{
                    sx{
                        marginBottom = 10.px
                        marginRight = 7.px
                    }

                }
                +"Dilan Kaplan"
            }

            Divider{}
          Box{
              sx{
                  hover {
                      textDecoration = None.none
                      backgroundColor = Color("#AE0E30")
                      color = Color("#f9f9f9")
                  }
              }

            Link {
                to = "/personal-information"
                onClick = { closeMenu }
                css {
                    display = Display.flex
                    alignItems = AlignItems.center
                    marginBottom = 8.px
                    width = 100.pct
                    marginTop = 8.px
                    textDecoration =None.none
                    color = Color.currentcolor



                }

                PersonOutline {
                    sx {
                        marginRight = 9.px
                    }
                }
                +"Profile  "

            }
}
            Divider {

            }
 Box {
     sx{
         hover {
             textDecoration = None.none
             backgroundColor = Color("#AE0E30")
             color = Color("#f9f9f9")
         }
     }

     Link {
         to = "/settings"
         css {
             display = Display.flex
             alignItems = AlignItems.center
             marginBottom = 8.px
             marginTop = 8.px
             color = Color.currentcolor
             textDecoration = None.none

             hover {
                 textDecoration = None.none
                 backgroundColor = Color("#AE0E30")
             }

             focus {
                 outline = None.none

             }

         }
         Tune {
             sx {
                 marginRight = 9.px
                 textDecoration = TextDecoration.underline
             }
         }
         +"Settings "
     }
 }
            Divider {}
    Box {
        sx{
            hover {
                textDecoration = None.none
                backgroundColor = Color("#AE0E30")
                color = Color("#f9f9f9")
            }
        }
    Link {
        css {
            marginTop = 8.px
            display = Display.flex
            alignItems = AlignItems.center
            color = Color.currentcolor
            textDecoration = None.none
            marginBottom = 8.px

        }
        onClick = {
            handleLogout().catch { error ->
                console.error("Error logging out", error)
                alert("Cannot log out. Please try again later.")
            }
        }

        Logout {
            sx {
                marginRight = 9.px
            }
        }
        +"Logout "
    }
}
        }
    }
}

