package tech.gensert.portal.common.model

import kotlinx.serialization.Serializable

@Serializable
actual data class CheckoutSession(
    actual var mode: String? = null,
    actual var successUrl: String,
    actual var interval: String,
    actual var user: User,
    actual var stripeCustomerId: String?=null
    ) : BaseModel()
