package tech.gensert.portal.web.config.msal

import tech.gensert.portal.common.model.config.BuildEnv
import kotlin.js.json

private val clientId = BuildEnv.clientId
private val tenantName = BuildEnv.tenantName
private val appIdUri = "api://${BuildEnv.backendClientId}/.default"

/*
Make sure to update the redirectUri when working locally
 */
val msalConfig = json(
    "auth" to json(
        "clientId" to clientId,
        "authority" to "https://$tenantName.ciamlogin.com",
        "redirectUri" to "/",
        "postLogoutRedirectUri" to "/",
        "knownAuthorities" to arrayOf("$tenantName.ciamlogin.com"),
        "navigateToLoginRequestUrl" to false
    ),
    "cache" to json(
        "cacheLocation" to "localStorage",
        "storeAuthStateInCookie" to true
    ),
    "scopes" to arrayOf(appIdUri, "openid"),
    "system" to json(
        "allowRedirectInIframe" to true
    )
)