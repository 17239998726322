package tech.gensert.portal.common.model

import io.ktor.client.engine.js.*
import io.ktor.http.*
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import tech.gensert.portal.common.config.Endpoint
import tech.gensert.portal.common.model.config.BuildEnv
import tech.gensert.portal.common.model.config.makeRequest

actual class SessionService {
    actual suspend fun createSession(
        authToken: String?,
        checkoutSession: CheckoutSession,
        productId: String
    ): CheckoutPaymentUrl {
        val url = "${BuildEnv.backendUrl}${Endpoint.SESSION_URL}"
        val requestBody = Json.encodeToString(checkoutSession)

        val response: CheckoutPaymentUrl = makeRequest(
            url = url,
            method = HttpMethod.Post,
            body = requestBody,
            parameters = mapOf("productId" to productId)
        )

        return response

    }

    actual suspend fun getUserSession(userId: String): Flow<CheckoutSessionResponse> {
        throw JsError("Not yet implemented")
    }

    actual suspend fun expireSession(sessionId: String): CheckoutSessionResponse {
        throw JsError("Not yet implemented")
    }
}