package tech.gensert.portal.web.routes

import CardShowcase
import ErrorPage
import js.core.jso
import kotlinx.browser.window
import react.router.dom.createHashRouter
import remix.run.router.LoaderFunction
import tech.gensert.portal.web.pages.LandingPageShowcase
import tech.gensert.portal.web.pages.Page
import tech.gensert.portal.web.showcase.*
import kotlin.js.Promise

val MATERIAL_SHOWCASES: Array<out Showcase> = arrayOf(
    Showcase("/home", "Home Page", HomeShowcase),
    Showcase("personal-information", "Personal Information", PersonalInformationShowcase),
    Showcase("privacy", "Privacy and Security", PrivacyAndSecurityShowcase),
    Showcase("services", "Services", CardShowcase),
    Showcase("subscriptions", "Subscriptions", PaymentAndSubscriptionShowcase),
    Showcase("settings", "Settings", SettingsShowcase),
    Showcase("help", "Help", HelpShowcase)
)

val ShowcaseMaterialLoader: LoaderFunction = { args ->
    val showcaseId = args.params["showcaseId"]
    val showcase = MATERIAL_SHOWCASES.find { it.key == showcaseId }
    if (showcase != null) {
        Promise.resolve(showcase)
    } else {
        Promise { resolve, _ ->
            window.setTimeout({
                MATERIAL_SHOWCASES.firstOrNull()?.let { resolve(it) }
            }, 20000)
        }
    }

}


val PageLoader: LoaderFunction = { args ->
    Promise.resolve(MATERIAL_SHOWCASES)
}

/*
    We can certainly do better here by taking advantage of AuthenticatedTemplate and UnAuthenticatedTemplate
    provided by @azure/msal-react
    to conditionally render pages based on the authentication status. Perhaps from the router directly.
*/
val appRouter = createHashRouter(
    arrayOf(
        jso {
            path = "/"
            loader = PageLoader
            Component = LandingPageShowcase
            ErrorBoundary = ErrorPage
        },
        jso {
            path = "/"
            loader = PageLoader
            Component = Page
            ErrorBoundary = ErrorPage
            children = arrayOf(
                jso {
                    path = "home"
                    loader = PageLoader
                    Component = HomeShowcase
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = ":showcaseId"
                    loader = ShowcaseMaterialLoader
                    Component = ShowcaseMaterial
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = "*"
                    Component = ErrorPage
                }

            )
        }
    )
)
